import React from 'react'
import { Link } from 'gatsby'
import { Heading, Badge, Flex } from 'theme-ui'
import Divider from '@components/Divider'

const styles = {
  wrapper: {
    justifyContent: `space-between`,
    alignItems: `end`
  },
  //Section title
  section: {
    position: `relative`,
    pl: [0, 3],
    mx: [`0`, 0],
    mb: 0,
    fontSize: '30px',
    color: '#2A205E',
    fontWeight: 400,
    '::before': {
      display: [`block`, `block`],
      bg: `#42B3FF`,
      content: `""`,
      borderRadius: `full`,
      position: `absolute`,
      height: `25px`,
      width: 5,
      top: '11px',
      left: 0,
      '@media (max-width: 743px)': {
        left: '-10px'
      }
    },
    '@media (max-width: 743px)': {
      marginLeft: '5px'
    }
  },
  //Section aside title
  'section-aside': {
    color: `red`,
    mx: `auto`,
    mb: 0
  },
  badge: {
    display: [`block`, `block`],
    mb: 0,
    bg: '#ffffff',
    border: '1px solid #42B3FF',
    width: '100px',
    color: '#42B3FF',
    textAlign: 'center',
    fontSize: '15px',
    height: '45px',
    paddingTop: '7px',
    '&:hover': {
      bg: '#42B3FF',
      color: '#ffffff'
    }
  }
}

const SectionTitle = ({ title, titleLink, omitTitle, variant }) => {
  const linkProps = titleLink && {
    as: Link,
    to: '/novedades'
  }

  return !omitTitle && (title || titleLink) ? (
    <>
      <Flex sx={styles.wrapper}>
        {title && (
          <Heading variant='h2' sx={styles[variant]} {...linkProps}>
            {title}
          </Heading>
        )}
        {titleLink && (
          <Badge variant='tag' sx={styles.badge} {...linkProps}>
            Ver más
          </Badge>
        )}
      </Flex>
      <Divider />
    </>
  ) : null
}

export default SectionTitle
