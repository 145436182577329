import React from 'react'
import { Container, Flex, Box, Badge, Link, Text, css } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'

import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import Section from '@components/Section'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

import { FaRegClock } from 'react-icons/fa'

const styles = {
  container: {
    margin: 'auto',
    width: '100%',
    '@media (max-width: 420px)': {
      width: '95%'
    }
  },
  wrapper: {
    flex: [`100%`],
    minWidth: 300,
    maxWidth: [400, '600px', '600px', '600px'],
    cursor: `pointer`,
    p: 3,
  },
  card: {
    overflow: `hidden`,
    height: `full`,
    padding: 10,
    borderTop: '10px solid #42B3FF',
    '@media (max-width: 1920px)': {
      flexDirection: `column`,
    }
  },
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `row`],
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 3
  },
  footerWrapper: {
    alignItems: `center`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  },
  image: {
    display: [`none`, `block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    minHeight: `15rem`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: 42,
    bg: `omegaLighter`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    }
  },
  title: {
    fontSize: 30,
    color: '#2A205E',
    marginLeft: '10px'
  },
  containerTitle: {
    '@media (max-width: 1920px)': {
      margin: 'auto',
      maxWidth: '73rem'
    },
    '@media (max-width: 1550px)': {
      maxWidth: '73rem'
    },
    '@media (max-width: 1170px)': {
      maxWidth: '36rem'
    },
    '@media (max-width: 765px)': {
      maxWidth: '40rem'
    },
    '@media (max-width: 632px)': {
      maxWidth: '27rem'
    }
  },
  contentCard: {
    flex: [0, 0, `auto`],
    fontSize: 17,
    display: 'block',
    margin: '2rem 0',
    color: '#6B6767',
    fontWeight: 600,
    '@media (max-width: 632px)': {
      margin: 0
    }
  }
}

export const translateDate = (text) => {
  const date = text[4].text.split(" ")

  const month = date[0]
  const day = date[1].replace(',', '')
  const year = date[2]

  let translatedMonth

  switch (month) {
    case 'January':
      translatedMonth = 'Enero'
      break;
    case 'February':
      translatedMonth = 'Febrero'
      break;
    case 'March':
      translatedMonth = 'Marzo'
      break;
    case 'April':
      translatedMonth = 'Abril'
      break;
    case 'May':
      translatedMonth = 'Mayo'
      break;
    case 'June':
      translatedMonth = 'Junio'
      break;
    case 'July':
      translatedMonth = 'Julio'
      break;
    case 'August':
      translatedMonth = 'Agosto'
      break;
    case 'September':
      translatedMonth = 'Septiembre'
      break;
    case 'October':
      translatedMonth = 'Octubre'
      break;
    case 'November':
      translatedMonth = 'Noviembre'
      break;
    case 'December':
      translatedMonth = 'Diciembre'
      break;
  }

  return `${day} de ${translatedMonth} del ${year}`
}

const BlogBlock01 = ({ content: { text, collection, buttons } }) => (
  <Box sx={styles.container}>
    <Box sx={styles.containerTitle}>
      <Section title="Novedades" titleLink="/blog">
      </Section>
    </Box>
    {collection && (
      <Box sx={styles.container}>
        <Reveal effect='fadeIn'>
          <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -3 }}>
            {collection.map(
              ({ container, text, images, avatar, buttons }, index) => (
                <Box key={`item-${index}`} sx={styles.wrapper}>
                  <ContentContainer
                    content={container}
                    variant='cards.interactive'
                    sx={styles.card}
                  >
                    <Flex as='article' sx={styles.content}>
                      {/* Image */}
                      <Box sx={{ flex: [0, 1], m: 2, mb: [null, null, null, 0] }}>
                        <Box sx={styles.imageWrapper}>
                          <ContentImages
                            content={{ images }}
                            sx={styles.image}
                            imageEffect='fadeIn'
                          />
                        </Box>
                      </Box>
                      <Box sx={styles.body}>
                        {/* Category */}
                        {/* Title */}
                        <ContentText
                          content={text?.[1]}
                          variant='small'
                          sx={styles.contentCard}
                        />
                        {/* Excerpt */}
                        {/* <ContentText
                          content={text?.[2]}
                          variant='small'
                          sx={{ flex: `auto`, mb: 3 }}
                        /> */}
                        {/* Footer */}
                        <Box sx={{ variant: `text.small` }}>
                          <Flex sx={styles.footerWrapper}>
                            <ContentImages
                              content={{ images: [avatar] }}
                              sx={styles.avatar}
                              imageEffect='fadeIn'
                            />
                            <Flex sx={styles.postInfo}>
                              {/* Author */}
                              <ContentText
                                content={text?.[3]}
                                sx={{
                                  display: `inline-block`,
                                  flexBasis: `full`,
                                  color: 'blue',
                                  fontSize: 15
                                }}
                              >
                                <Link color={'#27A8FF'} style={{ fontWeight: 600 }}>
                                  {text?.[3]?.text}
                                </Link>
                              </ContentText>
                              {/* Info */}
                              <Flex sx={{ alignItems: `center`, color: '#6B6767' }}>
                                <ContentText
                                  content={text?.[4]}
                                  sx={{ display: `inline-block`, color: '#6B6767' }}
                                  mr='2'
                                >
                                  {/* {text?.[4]?.textGroup?.[0]} */}
                                  {translateDate(text)}
                                </ContentText>
                                {/* ESTO AGREGA EL RELOJ */}
                                {/* {text?.[5] && <FaRegClock />}
                                <ContentText
                                  content={text?.[5]}
                                  sx={{ display: `inline-block`, color: '#6B6767' }}
                                  ml='2'
                                /> */}
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                        {buttons && (
                          <>
                            <Divider space={2} />
                            <ContentButtons content={buttons} />
                          </>
                        )}
                      </Box>
                    </Flex>
                  </ContentContainer>
                </Box>
              )
            )}
          </Flex>
        </Reveal>
      </Box>
    )}
    {buttons && (
      <>
        <Divider space={3} />
        <ContentButtons content={buttons} />
      </>
    )}
  </Box>
)

export default WithDefaultContent(BlogBlock01)
